<template>
  <div class="env-container">
    <!-- 室内环境监测传感器 -->
    <!-- 顶部搜索 -->
    <div class="filter-panel">
      <CSSelect i-width="36rpx" style="width: 230px; margin-right: 0">
        <el-date-picker
            type="datetime"
            placeholder="请选择开始时间"
            v-model="conditions.startTime"
            style="width: 214px"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            prefix-icon="el-icon-time"
            :editable="false"
        >
        </el-date-picker>
      </CSSelect>
      <span class="inline-gray"> </span>
      <CSSelect i-width="36rpx" style="width: 230px">
        <el-date-picker
            type="datetime"
            placeholder="请选择结束时间"
            v-model="conditions.endTime"
            style="width: 214px"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            prefix-icon="el-icon-time"
            :editable="false"
        >
        </el-date-picker>
      </CSSelect>
      <div
          style="
          vertical-align: middle;
          margin-bottom: 15px;
          display: inline-block;
        "
      >
        <input
            type="text"
            placeholder="搜索设备编号/设备型号/厂家品牌/设备所在位置"
            v-model="conditions.queryInput"
            style="
            color: #000;
            width: 320px;
            height: 30px;
            padding-left: 10px;
            border-radius: 4px;
            border: 1px solid #979797;
            margin-right: 20px;
          "
        />
      </div>
      <button type="button" class="btn btn-primary btn-sm" @click="query()">
        查询
      </button>
    </div>
    <!-- //表格 -->
    <div class="result-panel">
      <CSTable :thead-top="filterHeight">
        <template v-slot:thead>
          <tr>
            <th>获取数据时间</th>
            <th>设备编号</th>
            <th>设备型号</th>
            <th>厂家品牌</th>
            <th>设备所在位置</th>
            <th>湿度</th>
            <th>温度</th>
            <th>PM2.5</th>
            <th>PM10</th>
            <th>总挥发性有机物</th>
            <th>二氧化碳</th>
            <th>大气压</th>
            <th>光照</th>
            <th>臭氧</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="item in queryList" :key="item.id">
            <td class="timeR">
              <span>{{ item.createTime }}</span>
            </td>
            <td>
              <span>{{ item.no }}</span>
            </td>
            <td>
              <span>{{ item.model }}</span>
            </td>
            <td>
              <span>{{ item.brandName }}</span>
            </td>
            <td>
              <span>{{ item.specificLocation }}</span>
            </td>
            <td>
              <span>{{ item.humidity }}%RH</span>
            </td>
            <td>
              <span>{{ item.temperature }}℃</span>
            </td>
            <td>
              <span>{{ item.illumination }}μg/m<sup>3</sup></span>
            </td>
            <td>
              <span>{{ item.pmTen }}μg/m<sup>3</sup></span>
            </td>
            <td>
              <span>{{ item.organics }}ppb</span>
            </td>
            <td>
              <span>{{ item.carbonDioxide }}ppm</span>
            </td>
            <td>
              <span>{{ item.barometricPressure }}hPa</span>
            </td>
            <td>
              <span>{{ item.light }}</span>
            </td>
            <td>
              <span>{{ item.ozone }}ppm</span>
            </td>
          </tr>
        </template>
      </CSTable>
      <Pagination
          :key="'env'"
          name="pagination"
          componentName="Paginations"
      ></Pagination>
    </div>
  </div>
</template>

<script>
import CSTable from "@/components/common/CSTable";
import CSSelect from "@/components/common/CSSelect";
import Pagination from "@/components/Pagination.vue";
import {envIndoorUrl} from "@/requestUrl";
import dayjs from "dayjs";

export default {
  data() {
    return {
      filterHeight: 0,
      conditions: {
        startTime: dayjs().subtract(30, "day").format("YYYY-MM-DD") + " 00:00",
        endTime: dayjs().format("YYYY-MM-DD") + " 23:59",
        queryInput: "",
      },
      queryList: [],
    };
  },
  components: {
    CSTable,
    CSSelect,
    Pagination,
  },
  mounted() {
    this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    window.addEventListener("resize", () => {
      this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    });
    this.$vc.on(this.$route.path, "pagination", "page_event", (res) => {
      this.query(res);
    });
    window.addEventListener("keydown", this.queryDown);
    this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
      this.query(pageNo);
    });
    this.query();
  },
  methods: {
    //查询
    query(page = 1, Size = 10) {
      this.$fly
          .post(envIndoorUrl, {
            regionCode: this.$vc.getCurrentRegion().code, //区域编码 //区域code
            startDate: this.conditions.startTime, //起始时间
            endDate: this.conditions.endTime, //结束时间
            pageSize: Size,
            pageNo: page,
            search: this.conditions.queryInput,
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            if (page == 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total || res.data.datas.length,
                pageSize: Size,
                currentPage: page,
              });
            }
            this.queryList = res.data.datas;
          });
    },
    queryDown(e) {
      if (e.keyCode == 13) {
        this.query();
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.queryDown);
    console.log("销毁室内");
  },
  deactivated(){
     window.removeEventListener("keydown", this.queryDown);
  }
};
</script>

<style scoped>
th {
  text-align: center;
  font-size: 13px;
}

td {
  text-align: center;
  font-size: 13px;
  min-width: 100px;
  box-sizing: border-box;
  padding-left: 10px !important;
  padding-right: 10px !important;
  min-width: 100px !important;
}

.timeR {
  min-width: 140px !important;
}

</style>
